import React from "react";
import styled from "styled-components";

import OfferingsBlock from "@components/home/offeringsBlock";
import { validateType } from "@util/validateType";
import BrandsBlock from "@components/home/brandsBlock";
import FeaturedWork from "@components/home/featuredWork";
import Testimonials from "@components/home/testimonials";
import FeaturedUpdates from "@components/home/featuredUpdates";
import ColumnContent from "./columnContent";
import {
  BlocksContent,
  CardCarousel,
  HubspotFormBlock,
  Image,
  ImageTextColumn,
  Video,
  VideoTextColumn,
} from "./sub";
import { FluidObject } from "gatsby-image";
import ImageColumns from "@components/workInner/imageColumns";
import ScrollContainer from "@components/workInner/scrollContainer";
import BackgroundImageWithText from "@components/workInner/backgroundImageWithText";
import TextAndCapabilities from "@components/workInner/textAndCapabilities";
import {
  isSanityTextAndCapabilities,
  isSanityTextBlocksAndCapabilities,
} from "@util/types";
import { Container } from "@util/standard";
import RowContent from "./rowContent";
import { CenteredBlockContainer } from "../../templates/template.styles";

interface Props {
  data: any[];
  pageData?: any;
  isHome?: boolean;
}

export function Blocks({ data, pageData, isHome }: Props) {
  if (!data) return null;

  return (
    <Wrapper className="blocks-wrapper">
      {data.map((block, index) => {
        if (!block) return null;
        if (validateType.isOfferingsBlock(block)) {
          return <OfferingsBlock key={index} data={block} />;
        }
        if (validateType.isBrands(block)) {
          return <BrandsBlock key={index} data={block} />;
        }
        if (validateType.isFeaturedWorks(block)) {
          return <FeaturedWork key={index} data={block} />;
        }
        if (validateType.isTestimonialsCollection(block)) {
          return <Testimonials key={index} data={block} />;
        }
        if (validateType.isFeaturedUpdates(block)) {
          return <FeaturedUpdates key={index} data={block} />;
        }
        if (validateType.isColumnContent(block)) {
          return <ColumnContent key={index} data={block} />;
        }
        if (validateType.isCardCarousel(block)) {
          return <CardCarousel key={index} data={block} />;
        }
        if (validateType.isHubspotFormBlock(block)) {
          return <HubspotFormBlock key={index} data={block} />;
        }
        if (validateType.isColumn(block)) {
          return (
            <Container
              key={index}
              width="80%"
              mobileWidth="90%"
              className="block-content-wrapper"
            >
              <BlocksContent
                blocks={block._rawColumnContent}
                isH1={index === 0 && !isHome}
              />
            </Container>
          );
        }
        if (validateType.isImageBlock(block)) {
          return (
            <Image
              key={index}
              fluid={block?.image?.asset?.fluid as FluidObject}
              width={`${block?.width ?? 100}%`}
            />
          );
        }
        if (validateType.isVideoBlock(block)) {
          return (
            <Video
              background
              key={index}
              url={block?.vimeoURL as string}
              volume={false}
              foregroundOpen
            />
          );
        }
        if (validateType.isImageColumns(block)) {
          return <ImageColumns key={index} data={block} />;
        }
        if (validateType.isScrollContainer(block)) {
          return <ScrollContainer key={index} data={block} />;
        }
        if (validateType.isBackgroundImageWithText(block)) {
          return <BackgroundImageWithText key={index} data={block} />;
        }
        if (isSanityTextAndCapabilities(block)) {
          return (
            <TextAndCapabilities
              data={block}
              capabilities={pageData?.roles}
              siteSlug={pageData?.website}
              key={index}
            />
          );
        }

        if (isSanityTextBlocksAndCapabilities(block)) {
          return (
            <TextAndCapabilities
              data={block}
              capabilities={pageData?.roles}
              siteSlug={pageData?.website}
              key={index}
            />
          );
        }

        if (validateType.isRowContent(block)) {
          return <RowContent key={index} data={block} />;
        }

        if (validateType.isImageTextColumn(block)) {
          return <ImageTextColumn key={index} data={block} />;
        }

        if (validateType.isVideoTextColumn(block)) {
          return <VideoTextColumn key={index} data={block} />;
        }
        if (validateType.isSanityColumn(block)) {
          return (
            <CenteredBlockContainer key={block?._key}>
              <BlocksContent
                // @ts-ignore
                blocks={block?._rawColumnContent}
              />
            </CenteredBlockContainer>
          );
        }

        return null;
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 100px auto 0 auto;
  row-gap: 50px;

  @media only screen and (max-width: 768px) {
    row-gap: 50px;
    margin: 50px auto;
  }
`;
