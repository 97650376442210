import React from "react";
import styled from "styled-components";

import { Accordion, BlocksContent, Link } from "@components/shared/sub";
import {
  Maybe,
  SanityTextAndCapabilities,
  SanityWhatWeDoInner,
  SanityTextBlocksAndCapabilities
} from "@graphql-types";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { isSanityTextAndCapabilities, isSanityTextBlocksAndCapabilities } from "@util/types";

interface Props {
  data: SanityTextAndCapabilities | SanityTextBlocksAndCapabilities;
  capabilities: Maybe<Maybe<SanityWhatWeDoInner>[]> | undefined;
  siteSlug: Maybe<string> | undefined;
}

interface CapabilitiesProps {
  capabilities: Maybe<Maybe<SanityWhatWeDoInner>[]> | undefined;
  siteSlug: Maybe<string> | undefined;
  hideOnMobile?: boolean;
}

const Wrapper = styled(Container)`
  justify-content: space-between;
  width: 70%;
  margin: 0 auto 80px auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    flex-direction: column;
    margin: 0 auto 25px auto;
  }
`;

const TextWrapper = styled(Container)<{
  fontWeight?: Maybe<string | undefined>;
}>`
  flex-direction: column;
  width: 70%;
  h2 {
    font-size: 40px;
    margin-bottom: 0px;
    &:first-child {
      margin-top: 0px;
    }
  }
  h3 {
    font-size: 20px;
  }
  p {
    margin-bottom: 0px;
  }
  h2,
  h3,
  h4,
  p {
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    h2 {
      font-size: 30px;
    }
  }
`;

const Title = styled.h3`
  margin: 10px 0;
`;

const Capability = styled.p`
  margin: 15px 0 0 0;
  text-align: right;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: left;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 15px;
  }
`;

const SiteSlug = styled.a`
  margin: 15px 0 0 0;
  text-align: right;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: left;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:visited {
    color: ${colors.flightBlue};
  }

  margin: 15px 0 0 0;
  text-align: right;
  text-decoration: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: left;
  }
`;

const CapabilitiesWrapper = styled(Container)<{ hideOnMobile?: boolean }>`
  flex-direction: column;
  width: 30%;
  justify-content: flex-end;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 30px 0 0 0;
    width: 100%;
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`};
  }
`;

function TextAndCapabilities({ data, capabilities, siteSlug }: Props) {
  if (data == null) return null;

  return (
    <Wrapper>
      {isSanityTextAndCapabilities(data) && (
        <TextWrapper fontWeight={data.fontWeight}>
          <BlocksContent blocks={data.text?._rawColumnContent} />
        </TextWrapper>
      )}
      {isSanityTextBlocksAndCapabilities(data) && data.textBlocks && (
        <TextWrapper>
          {data.textBlocks.map((block, index) => {
            if (block == null) return null;

            return (
              <Container width="100%" flexDirection="column">
                <DesktopContainer width="100%" margin="0 0 20px 0">
                  <Title>{block.title}</Title>
                  <BlocksContent blocks={block.text?._rawColumnContent} />
                </DesktopContainer>
                <MobileContainer width="100%">
                  <Accordion
                    titleChildren={<Title>{block.title}</Title>}
                    useBorder
                    useTopBorder={index === 0}
                  >
                    <BlocksContent blocks={block.text?._rawColumnContent} />
                  </Accordion>
                </MobileContainer>
              </Container>
            );
          })}
        </TextWrapper>
      )}

      {capabilities && data.capabilitiesDisplay && (
        <CapabilitiesBlock
          capabilities={capabilities}
          siteSlug={siteSlug}
          hideOnMobile={isSanityTextBlocksAndCapabilities(data)}
        />
      )}
    </Wrapper>
  );
}

export default TextAndCapabilities;

const CapabilitiesBlock = ({ capabilities, siteSlug, hideOnMobile }: CapabilitiesProps) => {
  if (capabilities == null) return null;

  const formatSiteSlug = (slug: string) => {
    if (slug.includes("https://www.")) {
      const splitSlug = slug.split("https://www.");
      return splitSlug[1];
    }
    return slug;
  };

  return (
    <CapabilitiesWrapper hideOnMobile={hideOnMobile}>
      {capabilities?.map((capability) => {
        if (capability == null) return;

        const { slug, name, pageContent } = capability;

        if (pageContent == null || pageContent.length === 0) {
          return <Capability>{name}</Capability>;
        }

        return <StyledLink internallink url={`${slug?.current}`} linktext={name} />;
      })}
      {siteSlug && (
        <SiteSlug href={siteSlug} target="_blank">
          {formatSiteSlug(siteSlug)}
        </SiteSlug>
      )}
    </CapabilitiesWrapper>
  );
};
