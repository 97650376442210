import React from "react";
import styled from "styled-components";

import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, colors } from "@util/constants";
import { Maybe, SanityColumnContent } from "@graphql-types";
import Slice from "./slice";

interface Props {
  className?: string;
  data: Maybe<SanityColumnContent> | undefined;
}

/**
 * Columns component is used to display two separate columns of content side by side.
 * On smaller screens, the columns will stack vertically.
 */
const ColumnContent = ({ className = "", data }: Props) => {
  if (!data) return null;
  if (data?.unpublish) return null;
  const { columnNoOne, columnNoTwo, flipColumns, bottomLine } = data;

  const renderColumn = (columnNode: any, isFirst?: boolean) => {
    return (
      <Column className={isFirst ? "first-column" : "second-column"}>
        {columnNode.map((block: any, index: number) => {
          if (!block) return null;
          return <Slice key={index} data={block} />;
        })}
      </Column>
    );
  };

  return (
    <Section
      className={className || "column-content"}
      flipColumns={Boolean(flipColumns)}
      bottomLine={Boolean(bottomLine)}
    >
      {renderColumn(columnNoOne, true)}
      {renderColumn(columnNoTwo)}
    </Section>
  );
};

export default ColumnContent;

const Section = styled.div<{
  height?: number;
  flipColumns: boolean;
  bottomLine: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
  flex-direction: ${({ flipColumns }) => (flipColumns ? "row-reverse" : "row")};
  ${({ bottomLine }) =>
    bottomLine && `border-bottom: 1px solid ${colors.flightBlue};`}
  column-gap: 24px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
    row-gap: 30px;
    padding: 0px 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  width: 100%;
  flex: 0 1 50%;
  justify-content: flex-start;

  .rich-text {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 10% auto;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: 8% auto;

      .blocks-content {
        p {
          margin: 0;
        }
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: initial;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: initial;
    height: 100%;
    position: relative;
    top: unset;
  }
`;
