import React, { useEffect, useState } from "react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import {
  Maybe,
  Query,
  SanityBlogs,
  SanityFeaturedUpdates,
} from "@graphql-types";
import { Container, H2, GridContainer } from "@util/standard";
import { Button } from "@sub";
import Preview from "@components/shared/preview";
import { MOBILE_BREAKPOINT, pages, fontSizes } from "@util/constants";
import { sanityClient } from "@util/sanity/client";
import { graphql, useStaticQuery } from "gatsby";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 90%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;
interface Props {
  data?: Maybe<SanityFeaturedUpdates> | undefined;
  updates?: Maybe<Maybe<SanityBlogs>[]> | undefined;
  display?: string;
}

const FeaturedUpdates = ({ data, updates, display }: Props) => {
  const title = data?.title || "Updates.";

  const { allSanityBlogs }: Query = useStaticQuery(graphql`
    {
      allSanityBlogs(limit: 3, sort: { fields: _createdAt, order: DESC }) {
        nodes {
          ...sanityBlogsPreview
        }
      }
    }
  `);

  const blogData =
    data?.display === "latest"
      ? allSanityBlogs?.nodes
      : updates || data?.featuredUpdates;

  return (
    <Wrapper>
      <Container
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <H2 fontSize={fontSizes.h1.default}>{title}</H2>
        <StyledWrapper
          hideOnMobile
          width="fit-content"
          alignItems="flex-end"
          margin="0"
        >
          <Button
            theme="base"
            text="View updates"
            linkTo={pages.updates}
            width="fit-content"
          />
        </StyledWrapper>
      </Container>
      <GridContainer
        repeat={3}
        tabletRepeat={1}
        columnGap="20px"
        rowGap="20px"
        width="100%"
      >
        {blogData?.map((blog) => {
          if (blog == null) {
            return null;
          }

          return (
            <Preview
              key={blog._id}
              title={blog.title}
              category={blog.category?.title}
              image={blog.image?.asset?.fluid as FluidObject}
              mobileImage={blog.mobileImage}
              slug={blog.slug?.current}
            />
          );
        })}
      </GridContainer>
    </Wrapper>
  );
};
export default FeaturedUpdates;

const StyledWrapper = styled(Container)`
  flex-shrink: 0;
`;
