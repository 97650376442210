import React, { useState } from "react";
import { navigate } from "gatsby";
import { FluidObject } from "gatsby-image";

import { Maybe, SanityOfferingsBlock, SanityPreview } from "@graphql-types";
import { P } from "@util/standard";
import { BlocksContent, Button, Image, LinkButton } from "@sub";
import {
  Wrapper,
  PreviewContainer,
  ImageWrapper,
  TextContainer,
  TextWrapper,
  ContentContainer,
  OfferingTitle,
  HeadingContainer,
} from "./offeringsBlock.styles";
import { pages, brandColorsArray } from "@util/constants";
import { CtaContainer } from "./homeHero.styles";
import { Overlay } from "@components/shared/preview";

interface Props {
  data: Maybe<SanityOfferingsBlock> | undefined;
}

interface PreviewProps {
  offering: SanityPreview;
  index: number;
}

const OfferingsBlock = ({ data }: Props) => {
  if (!data) return null;

  return (
    <Wrapper>
      <TextWrapper>
        <HeadingContainer>
          <div className="offerings-inner-wrapper">
            <BlocksContent blocks={data.textContent?._rawColumnContent} />
            <CtaContainer width="100%">
              {data.links?.map((cta) => {
                if (cta == null) {
                  return null;
                }
                return (
                  <Button
                    key={cta._key}
                    theme="whiteInverse"
                    text={cta?.linktext ?? ""}
                    linkTo={`${cta?.url}/` ?? "/"}
                    margin="0 20px 10px 0"
                    dimensions={{ height: "50px" }}
                  />
                );
              })}
            </CtaContainer>
          </div>
        </HeadingContainer>
      </TextWrapper>
      <ContentContainer>
        {data.offerings?.map((offering, index) => {
          if (offering == null) {
            return null;
          }

          return (
            <OfferingPreview
              key={offering._key}
              offering={offering}
              index={index}
            />
          );
        })}
      </ContentContainer>
    </Wrapper>
  );
};
export default OfferingsBlock;

const OfferingPreview = ({ offering, index }: PreviewProps) => {
  const [hover, setHover] = useState(false);
  const moreText = "Find out more";
  const firstWord = offering.title?.replace(/ .*/, "");
  const rest = offering.title?.split(" ").slice(1).join(" ");
  const color = brandColorsArray[index];

  return (
    <PreviewContainer
      onClick={() =>
        navigate(`${offering.slug?.current}/` ?? `${pages.whatWeDo}/`)
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ImageWrapper backgroundColor={color} hover={false}>
        <Image
          fluid={offering.image?.asset?.fluid as FluidObject}
          data={offering.image}
          objectFit="cover"
          minHeight="250px"
        />
        <Overlay hover={hover} />
      </ImageWrapper>

      <TextContainer>
        <OfferingTitle hover={hover}>
          {firstWord}
          <span>{rest}</span>
        </OfferingTitle>
        <P color="flightBlue">{offering.summary}</P>

        <LinkButton
          bold
          margin="15px 0 0 0"
          color="flightBlue"
          pathTo={offering.slug?.current}
          hover={hover}
        >
          {moreText}
        </LinkButton>
      </TextContainer>
    </PreviewContainer>
  );
};
