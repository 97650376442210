import {
  SanityAccordion,
  SanityBackgroundImageWithText,
  SanityBrands,
  SanityCardCarousel,
  SanityColumn,
  SanityColumnContent,
  SanityFeaturedUpdates,
  SanityFeaturedWorks,
  SanityHubspotFormBlock,
  SanityImageBlock,
  SanityImageColumns,
  SanityImageWithMeta,
  SanityOfferingsBlock,
  SanityRowContent,
  SanityScrollContainer,
  SanityTestimonals,
  SanityTextAndCapabilities,
  SanityVideoBlock,
} from "@graphql-types";

function assertType<T>(type: string) {
  return (data: any): data is T => data?._type === type;
}

export const validateType = {
  isOfferingsBlock: assertType<SanityOfferingsBlock>("offeringsBlock"),
  isBrands: assertType<SanityBrands>("brands"),
  isFeaturedWorks: assertType<SanityFeaturedWorks>("featuredWorks"),
  isTestimonialsCollection: assertType<SanityTestimonals>("testimonals"),
  isFeaturedUpdates: assertType<SanityFeaturedUpdates>("featuredUpdates"),
  isColumnContent: assertType<SanityColumnContent>("columnContent"),
  isCardCarousel: assertType<SanityCardCarousel>("cardCarousel"),
  isHubspotFormBlock: assertType<SanityHubspotFormBlock>("hubspotFormBlock"),
  isColumn: assertType<SanityColumn>("column"),
  isImageWithMeta: assertType<SanityImageWithMeta>("imageWithMeta"),
  isAccordion: assertType<SanityAccordion>("accordion"),
  isImageBlock: assertType<SanityImageBlock>("imageBlock"),
  isTextAndCapabilities: assertType<SanityTextAndCapabilities>(
    "textAndCapabilities"
  ),
  isVideoBlock: assertType<SanityVideoBlock>("videoBlock"),
  isImageColumns: assertType<SanityImageColumns>("imageColumns"),
  isScrollContainer: assertType<SanityScrollContainer>("scrollContainer"),
  isBackgroundImageWithText: assertType<SanityBackgroundImageWithText>(
    "backgroundImageWithText"
  ),
  isRowContent: assertType<SanityRowContent>("rowContent"),
  isServicesArray: assertType<any>("servicesArray"),
  isImageTextColumn: assertType<any>("imageTextColumn"),
  isVideoTextColumn: assertType<any>("videoTextColumn"),
  isSanityColumn: assertType<any>("column"),
};
