import React from "react";
import styled from "styled-components";

import Slice from "./slice";
import { TABLET_BREAKPOINT } from "@util/constants";
import { SanityRowContent } from "@graphql-types";

interface Props {
  data: SanityRowContent;
}

export default function RowContent({ data }: Props) {
  if (!data) return null;
  const { rowItems } = data;

  return (
    <RowWrapper>
      {rowItems?.map((item) => {
        if (!item) return null;
        return <Slice key={item._key} data={item} />;
      })}
    </RowWrapper>
  );
}

const RowWrapper = styled.div`
  margin: 60px auto;
  display: flex;
  justify-content: center;
  column-gap: 30px;
  row-gap: 60px;

  div {
    width: 30%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 40px auto;
    flex-direction: column;
    row-gap: 30px;

    div {
      width: 100%;
    }
  }
`;
