import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { Maybe, SanityTestimonals, SanityTestimonial } from "@graphql-types";
import { Container, H2, P } from "@util/standard";
import { Carousel, Image } from "@components/shared/sub";
import {
  colors,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
  fontSizes,
} from "@util/constants";

interface Props {
  title?: string | undefined | null;
  description?: string | undefined | null;
  data?: Maybe<SanityTestimonals> | undefined;
  testimonials?: Maybe<Maybe<SanityTestimonial>[]> | undefined;
}

interface TestimonialSlideProps {
  testimonial: Maybe<SanityTestimonial>;
}

const Testimonials = ({ data, title, description, testimonials }: Props) => {
  const testiData = testimonials || data?.testimonialsCollection;
  const testiTitle = title || data?.testimonialsTitle;
  const testiDescription = description || data?.testimonialsDescription;

  return (
    <Wrapper>
      <Container flexDirection="column">
        <TextWrapper>
          <H2 fontSize={fontSizes.h1.default} color="white">
            {testiTitle}
          </H2>
          <P color="white" margin="0 0 50px 0">
            {testiDescription}
          </P>
        </TextWrapper>
        <CarouselWrapper>
          <Carousel adaptiveHeight>
            {testiData?.map((testimonial) => {
              return (
                <TestimonialSlide
                  key={testimonial?._key}
                  testimonial={testimonial}
                />
              );
            })}
          </Carousel>
        </CarouselWrapper>
      </Container>
    </Wrapper>
  );
};
export default Testimonials;

const TestimonialSlide = ({ testimonial }: TestimonialSlideProps) => (
  <ContentWrapper>
    {/* <TestimonialImage>
      <Image
        fluid={testimonial?.featuredImage?.asset?.fluid as FluidObject}
        objectFit="contain"
        data={testimonial?.featuredImage}
      />
    </TestimonialImage> */}

    <ContentContainer>
      <StyledP color="white">{`"${testimonial?.text}"`} </StyledP>
      <StyledFrom color="white" bold>
        {testimonial?.from}
      </StyledFrom>
      <Image
        fluid={testimonial?.brandIcon?.asset?.fluid as FluidObject}
        data={testimonial?.brandIcon}
        maxHeight="60px"
        width="100px"
        objectFit="contain"
      />
    </ContentContainer>
  </ContentWrapper>
);

const Wrapper = styled(Container)`
  width: 100%;
  background-color: ${colors.flightBlue};
  padding: 100px 0 100px 0;
  margin-bottom: 80px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 50px 0 50px 0;
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled(Container)`
  width: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin: 0;
  }
`;

const TestimonialImage = styled.div`
  width: 70%;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 77%;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 40px 0px 0px 0px;
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 0px;
  }
`;

const StyledP = styled(P)`
  margin: 0px 0px 30px 0px;
  padding-right: 40px;

  font-size: 30px;
  line-height: 35px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-right: 0px;
    font-size: 20px;
    line-height: 25px;
  }
`;

const StyledFrom = styled(P)`
  margin: 0px 0px 19px 0px;
  padding-right: 40px;

  font-size: 22px;
  line-height: 35px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-right: 0px;
    font-size: 15px;
    line-height: 20px;
  }
`;

const CarouselWrapper = styled.div`
  .slick-prev {
    left: -40px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .slick-prev {
      left: -40px;
    }
    .slick-next {
      top: 25%;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;

    .slick-prev {
      top: -40px;
      left: 0;
      height: 20px;
      width: 20px;
    }

    .slick-next {
      top: -40px;
      left: 15%;
      height: 20px;
      width: 20px;
    }
  }
`;
