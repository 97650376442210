import styled from "styled-components";

import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, colors } from "@util/constants";
import { Container } from "@util/standard";
import { bounceTopAnimation } from "@util/styles";

export const Wrapper = styled(Container)<{
  backgroundUrl?: string | null | undefined;
}>`
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  width: 100%;
  height: 95vh;
  margin: 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: initial;
  }
`;

export const DownArrow = styled.img`
  display: none;
  position: absolute;
  width: 25px;
  height: auto;
  cursor: pointer;
  z-index: 1;
  bottom: 50px;
  right: 50%;

  ${bounceTopAnimation};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    /* display: block; */
  }
`;

export const ContentContainer = styled(Container)`
  flex-direction: column;
  margin: 0;
  position: relative;

  h1 {
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: auto;
  }
`;

export const CtaContainer = styled(Container)`
  margin: 25px 0 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -99;
  overflow: hidden;
  background-color: ${colors.flightBlue};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
  }
`;
