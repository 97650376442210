import { validateType } from "@util/validateType";
import React from "react";
import { Accordion, BlocksContent, Image, Video } from "./sub";
import ServicesArray from "./sub/servicesArray";

interface Props {
  data: any;
}

export default function Slice({ data }: Props) {
  if (!data) return null;

  if (validateType.isColumn(data)) {
    return (
      <BlocksContent
        blocks={data._rawColumnContent}
        maxWidth={data?.maxWidth}
      />
    );
  }

  if (validateType.isImageWithMeta(data)) {
    return <Image data={data} />;
  }

  if (validateType.isAccordion(data)) {
    const { title, content } = data;
    return (
      <Accordion
        titleChildren={<span style={{ fontSize: "25px" }}>{title}</span>}
        useTopBorder
      >
        <BlocksContent blocks={content?._rawColumnContent} />
      </Accordion>
    );
  }

  if (validateType.isVideoBlock(data)) {
    return (
      <Video
        height="50vh"
        width="100%"
        background
        url={data?.vimeoURL as string}
        volume={false}
        foregroundOpen
      />
    );
  }

  if (validateType.isServicesArray(data)) {
    return <ServicesArray data={data} />;
  }

  return null;
}
