import React from "react";
import styled from "styled-components";

import { Maybe, SanityBackgroundImageWithText } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, Image } from "@components/shared/sub";
import { colors, fontSizes, TABLET_BREAKPOINT } from "@util/constants";
import { FluidObject } from "gatsby-image";

interface Props {
  data: SanityBackgroundImageWithText;
}

const StyledContainer = styled(Container)<{
  fontWeight?: Maybe<string> | undefined;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto 120px auto;
  background-size: cover;
  color: ${colors.white};

  h1,
  h2,
  h3,
  p,
  span {
    color: white !important;
  }

  h2 {
    font-size: 50px;
    line-height: ${fontSizes.h1.default * 1.2}px;
  }

  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 25px auto;

    h2 {
      font-size: 30px;
      line-height: ${fontSizes.h1.mobile * 1.2}px;
      margin-top: 0px;
    }
  }
`;

const Gradient = styled(Container)`
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  background: linear-gradient(245.8deg, rgba(0, 0, 0, 0) 6.77%, rgba(0, 0, 0, 0.4) 71.34%);
`;

function BackgroundImageWithText({ data }: Props) {
  if (data == null) return null;

  return (
    <StyledContainer position="relative" width="100%" height="400px" fontWeight={data.fontWeight}>
      {data.image && (
        <Image
          data={data.image}
          fluid={data.image.asset?.fluid as FluidObject}
          isBackground
          backgroundStyle={{
            width: "100%",
            height: "100%",
            position: "absolute"
          }}
        />
      )}
      <Gradient />
      <Container margin="350px auto" tabletMargin="200px auto" zIndex={2} width="65%">
        <BlocksContent blocks={data.text?._rawColumnContent} />
      </Container>
    </StyledContainer>
  );
}

export default BackgroundImageWithText;
