import styled from "styled-components";

import {
  MOBILE_BREAKPOINT,
  colors,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, GridContainer, H2 } from "@util/standard";
import { moveDown, moveUp } from "@util/styles";

export const Wrapper = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 0 0;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 0 50px 0;
  }
`;

export const TextWrapper = styled(Container)`
  width: 100%;
  margin: 0;
  z-index: 1;

  h1,
  h2,
  h3,
  p,
  .heading-1 {
    color: ${colors.white};
  }
  background-color: ${colors.flightBlue};
`;

export const HeadingContainer = styled(Container)`
  width: 80%;
  margin: 100px auto 250px auto;
  flex-direction: column;

  .offerings-inner-wrapper {
    width: 80%;
    margin: 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: auto;
    margin: 60px 40px 70px 40px;

    .offerings-inner-wrapper {
      width: 100%;
      margin: 0;

      .blocks-content {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;

export const ContentContainer = styled(GridContainer)`
  flex-direction: row;
  /* padding: 50px 0 0 0; */
  width: 90%;
  margin: -150px auto auto auto;
  z-index: 2;
  gap: 20px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    margin-top: 40px;
    grid-row-gap: 30px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-column-gap: 20px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

export const TextContainer = styled(Container)`
  flex-direction: column;
  margin: 0;
  height: 100%;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div<{
  hover: boolean;
  backgroundColor: string;
}>`
  position: relative;
  width: 100%;
  ${moveDown};
  ${({ hover }) => hover && moveUp};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const PreviewContainer = styled(Container)`
  width: 100%;
  margin: 0;
  flex-direction: column;
  cursor: url("/cursorClick.svg"), auto;
  margin-bottom: 0;
`;

export const OfferingTitle = styled(H2)<{ hover?: boolean }>`
  width: fit-content;
  padding-bottom: 2px;
  line-height: 100% !important;

  span {
    display: block;
  }
`;
