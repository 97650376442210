import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { Maybe, SanityBrands, SanitySocialLink } from "@graphql-types";
import {
  SMALL_LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  animationType,
  fontSizes,
} from "@util/constants";
import { Container, GridContainer, P, H2 } from "@util/standard";
import { AnimationContainer, Image } from "@sub";

interface Props {
  title?: string | undefined | null;
  description?: string | undefined | null;
  brands?: Maybe<Maybe<SanitySocialLink>[]> | undefined;
  data?: Maybe<SanityBrands> | undefined;
}

const BrandsBlock = ({ data, title, description, brands }: Props) => {
  const brandsData = brands || data?.featuredBrands?.socialLinks;
  const brandsTitle = title || data?.brandsTitle;
  const brandsDescription = description || data?.brandsDescription;

  return (
    <Wrapper>
      <ContentContainer width="80%">
        <H2 fontSize={fontSizes.h1.default}>{brandsTitle}</H2>
        <P margin="0 0 20px 0">{brandsDescription}</P>
        <GridContainerStyled repeat={5} tabletRepeat={3} mobileRepeat={3}>
          {brandsData?.map((brand) => {
            if (brand == null) {
              return null;
            }

            return (
              <AnimationContainer
                key={brand?.socialIcon?.asset?._id}
                animationType={animationType.slideInBottom}
              >
                <Container height="100%" alignItems="center">
                  <Image
                    fluid={brand.socialIcon?.asset?.fluid as FluidObject}
                    objectFit="contain"
                    data={brand.socialIcon}
                  />
                </Container>
              </AnimationContainer>
            );
          })}
        </GridContainerStyled>
      </ContentContainer>
    </Wrapper>
  );
};
export default BrandsBlock;
const Wrapper = styled(Container)`
  width: 100%;
  margin: 100px 0 0 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px auto 0 auto;
  }
`;

const GridContainerStyled = styled(GridContainer)`
  margin: 40px 0px 140px 0px;
  grid-column-gap: 90px;
  grid-row-gap: 120px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    grid-column-gap: 50px;
    grid-row-gap: 80px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 40px 0px 100px 0px;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 40px 0px 50px 0px;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
`;

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: column;
  z-index: 1;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;
