import { Maybe, SanityServiceLink, SanityServicesArray } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import React from "react";
import styled from "styled-components";

interface Props {
  data: SanityServicesArray;
}

const ServicesArray = ({ data }: Props) => {
  console.log({ data });

  if (data == null) return null;

  const { gridColumns, value } = data;

  return (
    <StyledServicesArray columns={gridColumns ?? 0}>
      {value?.map((item: Maybe<SanityServiceLink>, index: number) => {
        if (item == null) return null;
        const { displayTitle, linkedService } = item;
        if (linkedService) {
          return (
            <a href={`/${linkedService?.slug?.current}/`}>{displayTitle}</a>
          );
        }
        return <p>{displayTitle}</p>;
      })}
    </StyledServicesArray>
  );
};

export default ServicesArray;

const StyledServicesArray = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns ?? 1}, 1fr);
  margin-top: 30px;
  a,
  p {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    margin: 0;
    text-decoration: none;
  }

  a {
    width: fit-content;

    background: linear-gradient(
        to right,
        rgba(100, 200, 200, 0),
        rgba(100, 200, 200, 0)
      ),
      linear-gradient(270deg, #0000ff 14.11%, #0000ff 65.01%), transparent;
    background-size: 100% 1px, 0 1px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover {
      background-size: 0 1px, 100% 1px;
      cursor: url("/cursorClick.svg"), auto;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
    }

    p,
    a {
      font-size: 20px;
    }
  }
`;
